.chat-list {
  width: 250px;
  border-right: 1px solid #ccc;
  padding: 10px;
  padding-bottom: 40px;
  background-color: #ffffff;
  height: 370px;
  overflow-y: auto;
}

.chat-item {
  padding: 8px;
  cursor: pointer;
  background-color: #fff;
  margin-bottom: 5px;
  margin-top: 5px;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.chat-item:hover {
  background-color: #e6e6e6;
}

.chat-item.selected {
  background-color: #198b58;
  /* Highlight color */
  font-weight: bold;
  /* Make the selected chat stand out */
}

.chatbox-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  transition: height 0.3s ease;
  box-shadow: 0px -4px 20px rgba(0, 0, 0, 0);
}

.chatbox-container.collapsed {
  box-shadow: 0px -4px 20px rgba(0, 0, 0, 0);
  height: 50px;
  width: 200px
    /* Collapsed height */
}

.chatbox-container.open {
  background-color: white;
  height: auto;
  /* Expanded height */
  height: 420px;
}

.chat-header {
  border-radius: 5px 5px 0px 0px;
  background-color: #007bff;
  color: white;
  padding: 15px 5px 5px 10px;
  cursor: pointer;
  display: flex;
}

.chat-box {
  padding: 10px;
}

.messages-container {
  flex: 1;
  height: 300px;
  padding-right: 10px;
  width: 290px;
  background-color: #f9f9f9;
  overflow-y: auto;
}

.message-user {
  margin-bottom: 10px;
  padding: 10px;
  background-color: #ffffff;
  border-radius: 4px;
  display: flex;
  justify-content: start;
}

.message {
  margin-bottom: 10px;
  padding: 10px;
  background-color: #DCF0E4;
  border-radius: 4px;
  display: flex;
  justify-content: end;
}

.input-container {
  display: flex;
  margin-top: 10px;
}

input {
  flex: 1;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

button {
  margin-left: 5px;
  padding: 8px 12px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.chatbox-container {
  border-radius: 10px;
  position: fixed;
  /* Stay on the page */
  bottom: 0;
  right: 20px;
  width: 700px;
  height: 400px;
  box-shadow: 0px -4px 20px rgba(0, 0, 0, 0);
  /* Adjust based on your design */
  z-index: 999;
  /* Make sure it's above everything else */
  display: flex;
  flex-direction: column;
  transition: height 0.3s ease;
}