@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/Roboto/Roboto-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/Roboto/Roboto-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

.roboto-font {
  font-family: 'Roboto', sans-serif;
}


.App {
  width: 100%;
  height: auto;
  align-items: center;
  flex-direction: column;
}

.loginME {
  padding-bottom: 85px;
  display: flex;
  justify-content: center;
  align-items: left;
  flex-direction: column;
  font-family: Futura, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.landImage {
  z-index: -1;
  max-width: 30%;
}

.body {
  margin: 0;
  padding: 0;
  /* justify-content: center; */
}


.page404 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  place-items: center;
  justify-content: center;
}

.item {
  width: 90%;
  height: 300px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  border: 1px solid lightgray;
  font-family: Futura, Avenir, Helvetica, sans-serif;
  margin: 30px;
  max-width: 100%;
  max-height: 100%;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  font-size: small;
}

.item:hover {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.item .footer #bot_Stat:hover {
  cursor: pointer;
}

.item .name {
  flex: 20%;
  border-bottom: 1px solid lightgray;
  background-color: #00C9FF;
  display: flex;
  /* Change from grid to flex */
  justify-content: space-between;
  /* Distribute space between items */
  align-items: center;
  /* Center items vertically */
  color: white;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 0 10px;
  /* Optional: Add some padding for better spacing */
}

.item .body {
  flex: 80%;
  display: flex;
  align-items: center;
  /* Center items vertically if needed */
  padding: 10px;
  /* Optional: Add padding for better spacing */
}

.item .body img {
  width: 30%;
  /* Occupy 30% of the container */
  object-fit: cover;
  /* Ensure the image covers the assigned area */
}

.item .body .text-content {
  flex: 1;
  padding-left: 10px;
  /* Add padding for better spacing */
}

.item .body .text-content .queryset_data {
  padding-left: 5px;
  font-weight: bold;
  width: 250px;
  /* Add padding for better spacing */
}

.item .lando_image {
  left: 42px;
  max-width: 350px;
  max-height: 150px;
  position: relative;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}

.item .footer {
  flex: 20%;
  border-top: 1px solid lightgray;
  display: flex;
  align-items: center;
  padding-left: 15px;
  background-color: dodgerblue;
  color: white;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  /*  so that price goes right and country left */
  justify-content: space-between;
}

.footer .username {
  flex: 50%;
}

.footer .buttons {
  flex: 50%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.likeBttn {
  color: rgba(250, 250, 250, 0.8);
}

.unlikeBttn {
  color: white;
}

.buttons label {
  margin-right: 20px;
  margin-left: 10px;
}

/* CREATE LISTING CSS */

.createItemPage {
  font-family: Futura, Avenir, Helvetica, sans-serif;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.formContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 500px;
  height: auto;
  padding: 20px;
  border: 5px solid #00C9FF;
  border-radius: 15px;
}

.formContainer:hover {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.formContainer #inputCreateItem {
  height: 40px;
  margin-bottom: 10px;
  border: 2px solid grey;
  border-radius: 5px;
  padding-top: 1px;
  padding-left: 10px;
  color: grey;
  font-size: 20px;
}

.formContainer #inputDescription {
  height: 190px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  margin-bottom: 10px;
  border: 2px solid grey;
  border-radius: 5px;
  padding-top: 1px;
  padding-left: 10px;
  color: grey;
  font-size: 20px;
}

.formContainer button {
  margin-top: 15px;
  height: 40px;
  border: none;
  background-color: #1dcaf9;
  border-radius: 5px;
  color: white;
}

.formContainer button:hover {
  cursor: pointer;
  background-color: rgb(37, 144, 252);
}

.formContainer span {
  color: black;
}

/* NAVBAR */

.navbar {
  width: 100%;
  height: 70px;
  border-radius: 20px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .2);
  background-color: dodgerblue;
  display: flex;
  margin-bottom: 12px;
  align-items: center;
  font-family: Futura, Avenir, Helvetica, sans-serif;
}

.navbar:hover {
  box-shadow: 0 5px 12px 0 rgba(36, 35, 35, 0.2);
}


.navbar .links {
  flex: 50%;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.navbar .loggedInContainer {
  flex: 50%;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-left: 10px;
}

a {
  margin-left: 20px;
  text-decoration: none;
  color: white;
}

.navbar button {
  width: 100px;
  height: 40px;
  cursor: pointer;
  color: #085c73;
  margin-right: 10px;
  background-color: white;
}

.navbar button:hover {
  cursor: pointer;
  color: white;
  background-color: #00C9FF;
}

.navbar h1 {
  text-align: right;
  margin-right: 15px;
  color: white;
  font-weight: lighter;
}

/* For the landing page hide it */
#landing .navbar {
  display: none;
  visibility: hidden;
}

.itemPage {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}

.leftSide {
  flex: 50%;
  height: calc(100vh - 70px);
  display: grid;
  place-items: center;
}

#indi {
  height: 50px;
  width: 100px;
  padding-right: 400px;
}

.itemNB {
  padding-top: 5px;
  font-family: Futura, Avenir, Helvetica, sans-serif;
  font-size: 40px;
  justify-content: center;
  place-items: center;
}

.purchaseButonito {
  background-color: #00C9FF;
  background-color: #c7f3cc;
  width: 209px;
  color: white;
}

.purchaseButonito:hover {
  cursor: pointer;
  background-color: rgb(37, 144, 252);
}

.rightSide {
  flex: 50%;
  height: calc(100vh - 70px);
  display: grid;
  place-items: center;
  display: flex;
  flex-direction: column;
}

.rightSide .placeBid {
  flex: 20%;
  width: 100%;
  display: grid;
  place-items: center;
  margin-top: 25px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.placeBid form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: auto;
  padding: 20px;
}

.placeBid span {
  color: rgb(109, 32, 32);
  font-family: 'Futura';
  font-size: 19px;
}

.placeBid input {
  font-size: 22px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  border: 2px solid #00C9FF;
}

.rightSide .listOfComments {
  flex: 80%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.listOfComments .comment {
  width: 70%;
  height: auto;
  padding: 30px;
  border: 1px solid lightgray;
  border-radius: 5px;
  margin-top: 20px;
}

#individual {
  height: 500px;
  width: 600px;
}

#individual .title,
#individual .footer {
  font-family: Futura, Avenir, Helvetica, sans-serif;
  font-size: 25px;
  display: flex;
  color: #00C9FF;
}

#individual .footerSmall {
  font-family: Futura, Avenir, Helvetica, sans-serif;
  font-size: 17px;
  display: flex;
  color: #115f74;
}

#individual .body {
  font-family: Futura, Avenir, Helvetica, sans-serif;
  font-size: 20px;
  padding-left: 20px;
  padding-right: 20px;
  display: grid;
  place-content: center;
  vertical-align: middle;
}

.loginContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  padding-top: 100px;
}

.loginContainer input {
  border: 2px solid dodgerblue;
}

.gradient-custom {

  /* fallback for old browsers */
  background: #43e97b;

  /* Chrome 10-25, Safari 5.1-6 */
  background: -webkit-linear-gradient(to right, rgba(67, 233, 123, 0.5), rgba(56, 249, 215, 0.5));

  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background: linear-gradient(90deg, #00C9FF 0%, #92FE9D 100%);
}

.gradient-reverse {

  /* fallback for old browsers */
  background: #00C9FF;

  /* Chrome 10-25, Safari 5.1-6 */
  background: -webkit-linear-gradient(to right, rgba(56, 249, 215, 0.5)), rgba(67, 233, 123, 0.5);

  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background: linear-gradient(90deg, #92FE9D 0%, #00C9FF 100%);
}

.linky {
  text-decoration: underline;
  color: #1d8cab;
}

.linky:hover {
  color: black;
}

.shake {
  padding-top: 5px;
  font-family: Futura, Avenir, Helvetica, sans-serif;
  font-size: 20px;
  color: brown;
  justify-content: center;
  place-items: center;
  animation: shake 1s infinite;
}

.carousel {
  border: 2px solid;
  border-radius: 10px;
}

.carousel .control-arrow {
  width: 70px;
  background-color: rgba(0, 0, 0, 0.5);
  /* Adjust arrow button background */
  border-radius: 30%;
  padding: 5px;
}

@keyframes shake {
  0% {
    transform: translate(2px, 1px) rotate(0deg);
  }

  10% {
    transform: translate(-1px, -2px) rotate(-2deg);
  }

  20% {
    transform: translate(-3px, 0px) rotate(3deg);
  }

  30% {
    transform: translate(0px, 2px) rotate(0deg);
  }

  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }

  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }

  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }

  70% {
    transform: translate(2px, 1px) rotate(-2deg);
  }

  80% {
    transform: translate(-1px, -1px) rotate(4deg);
  }

  90% {
    transform: translate(2px, 2px) rotate(0deg);
  }

  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

.shake:hover {
  text-decoration: underline;
  cursor: pointer;
  color: black;
}

.buttonito {
  margin-top: 10px;
  font-family: Futura;
  height: 40px;
  border: 1px solid white;
  background-color: #1dcaf9;
  border-radius: 5px;
  color: white;
}

.carousel {
  height: 220px !important;
}

.carousel .control-arrow,
.carousel.carousel-slider .control-arrow {
  z-index: 0 !important;
  display: none;
}


.carousel .control-dots {
  text-align: bottom;
}

/* react tag selector start */
.react-tags {
  position: relative;
  padding: .25rem 0 0 .25rem;
  border: 2px solid #afb8c1;
  border-radius: 6px;
  background: #fff;
  font-size: 1rem;
  line-height: 1.2;
  cursor: text
}

.react-tags.is-active {
  border-color: #4f46e5
}

.react-tags__label {
  display: none;
}

.react-tags__list {
  display: inline;
  padding: 0;
}

.react-tags__tag {
  width: auto !important;
  height: auto !important;
}

.react-tags__list-item {
  display: inline;
  list-style: none;
}

.react-tags__tag {
  margin: 0 .25rem .25rem 0;
  padding: .375rem .5rem;
  border: 0;
  border-radius: 3px;
  background: #eaeef2;
  font-size: inherit;
  line-height: inherit;
}

.react-tags__tag:hover {
  color: #fff;
  background-color: #4f46e5
}

.react-tags__tag:after {
  content: "";
  display: inline-block;
  width: .65rem;
  height: .65rem;
  clip-path: polygon(10% 0, 0 10%, 40% 50%, 0 90%, 10% 100%, 50% 60%, 90% 100%, 100% 90%, 60% 50%, 100% 10%, 90% 0, 50% 40%);
  margin-left: .5rem;
  font-size: .875rem;
  background-color: #7c7d86
}

.react-tags__tag:hover:after {
  background-color: #fff
}

.react-tags__combobox {
  display: inline-block;
  padding: .375rem .25rem;
  margin-bottom: .25rem;
  max-width: 100%
}

.react-tags__combobox-input {
  width: 75px;
  max-width: 100%;
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  background: none;
  font-size: inherit;
  line-height: inherit
}

.react-tags__combobox-input::placeholder {
  color: #7c7d86;
  opacity: 1
}

.react-tags__listbox {
  position: absolute;
  z-index: 10;
  top: calc(100% + 5px);
  left: -2px;
  right: -2px;
  max-height: 12.5rem;
  overflow-y: auto;
  background: #fff;
  border: 1px solid #afb8c1;
  border-radius: 6px;
  box-shadow: #0000001a 0 10px 15px -4px, #0000000d 0 4px 6px -2px
}

.react-tags__listbox-option {
  padding: .375rem .5rem
}

.react-tags__listbox-option:hover {
  cursor: pointer;
  background: #eaeef2
}

.react-tags__listbox-option:not([aria-disabled=true]).is-active {
  background: #4f46e5;
  color: #fff
}

.react-tags__listbox-option[aria-disabled=true] {
  color: #7c7d86;
  cursor: not-allowed;
  pointer-events: none
}

.react-tags__listbox-option[aria-selected=true]:after {
  content: "✓";
  margin-left: .5rem
}

.react-tags__listbox-option[aria-selected=true]:not(.is-active):after {
  color: #4f46e5
}

.react-tags__listbox-option-highlight {
  background-color: #fd0
}

/* react tag selector end */

.button_PDF {
  margin-top: 10px;
  font-family: Futura;
  height: 40px;
  width: 100px;
  border: 1px solid white;
  background-color: rgb(240, 24, 168);
  border-radius: 5px;
  color: white;
  cursor: pointer;
}

.button_PDF:hover {
  border-color: white;
  cursor: pointer;
  background-color: rgb(255, 149, 220);
}

.button_XLS {
  margin-top: 10px;
  font-family: Futura;
  height: 40px;
  width: 100px;
  border: 1px solid white;
  background-color: rgb(77, 238, 63);
  border-radius: 5px;
  color: white;
  cursor: pointer;
}

.button_XLS:hover {
  border-color: white;
  cursor: pointer;
  background-color: rgb(159, 226, 153);
}

.button_search {
  margin-top: 10px;
  font-family: Futura;
  height: 40px;
  width: 100px;
  border: 1px solid white;
  background-color: rgb(60, 153, 247);
  border-radius: 5px;
  color: white;
  cursor: pointer;
}

/* Basic reset for the select element */
.custom-select {
  -webkit-appearance: none;
  /* Remove default appearance */
  -moz-appearance: none;
  appearance: none;
  border: none;
  /* Remove border */
  background: none;
  /* Remove background */
  cursor: pointer;
  /* Make cursor pointer */
  padding: 3px;
  /* Padding for better look */
  font-size: 14px;
  color: black;
  /* Font size for better readability */
}

/* Add a custom arrow to the select element */
.custom-select::after {
  content: '\25BC';
  /* Unicode for a downward arrow */
  position: absolute;
  right: 10px;
  /* Position it on the right side */
  top: 50%;
  transform: translateY(-50%);
  /* Center it vertically */
  pointer-events: none;
  /* Make it unclickable */
}

/* Ensure the select element does not overflow */
.custom-select {
  display: inline-block;
  width: auto;
  /* Adjust width as needed */
}

/* Apply styles for the label look */
.custom-select.label-look {
  border: 1px solid black;
  /* Add a border */
  border-radius: 5px;
  /* Light background */
  position: relative;
  /* Position for the arrow */
}

/* Style on hover to show original look */
.custom-select.label-look:hover {
  -webkit-appearance: menulist;
  /* Restore original dropdown on hover */
  -moz-appearance: menulist;
  appearance: menulist;
  height: 28px;
  border: 1px solid #777;
  /* Change border color on hover */
  background-color: white;
  /* White background on hover */
}

.wa_stat_img {
  width: 18px;
  height: 18px;
  cursor: "pointer";
  -webkit-user-drag: none;
  transform: translateX(-13px)translateY(5px);
}

.wa_stat_img:hover {
  cursor: pointer;
}

.button_search:hover {
  border-color: white;
  cursor: pointer;
  background-color: rgb(105, 180, 255);
}

.buttonito:hover {
  cursor: pointer;
  background-color: rgb(61, 155, 248);
}

.buttonitoSelect {
  margin-top: 15px;
  font-family: Futura;
  font-size: 18px;
  height: 40px;
  background-color: #ffffff;
  border-radius: 5px;
  border-color: rgb(58, 192, 105);
  color: rgb(58, 192, 105);
}

.buttonitoSelect:hover {
  cursor: pointer;
  background-color: rgb(58, 192, 105);
  color: white;
}

.buttonitoInfo {
  margin-top: 15px;
  font-family: Futura;
  height: 40px;
  border: none;
  background-color: #b4f7ab;
  border-radius: 5px;
  color: white;
}

.buttonitoInfo:hover {
  cursor: pointer;
  background-color: rgb(58, 192, 105);
}

.buttonitoReverse {
  font-family: Futura;
  margin-top: 15px;
  height: 40px;
  border: none;
  background-color: #f7cdab;
  border-radius: 5px;
  color: white;
}

.buttonitoReverse:hover {
  cursor: pointer;
  background-color: #e6934f;
}

.headerito {
  justify-content: center;
  font-size: 25px;
  font-family: Futura, Avenir, Helvetica, sans-serif;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.headerito .year-picker-input {
  width: "182px";
  height: "25px";
  margin-top: "10px";
  border-radius: "5px";
  border: "1px solid grey"
}

.outer-container {
  border: 2px solid;
  border-radius: 5px;
  max-width: 100%;
}

.sup_add_table .tr .td {
  width: 120px;
}

.table-container {
  max-height: 55.2vh;
  /* Adjust height as necessary to show 5 rows */
  overflow-y: auto;
}

.supplier-table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}

.supplier-table th {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: #6b6b6b;
  /* Background color for header */
  z-index: 1;
  /* Ensure the header stays on top */
}

.supplier-table th,
.supplier-table td {
  overflow-wrap: break-word;
  border: 1px solid black;
  padding: 8px;
  text-align: left;
  white-space: wrap;
}

.supplier-table th {
  background-color: #f2f2f2;
}

.supplier-table td button {
  padding: 4px 8px;
  border: none;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  border-radius: 3px;
}

.supplier-table td button:hover {
  background-color: #0056b3;
}

.query_form {
  padding: 10px;
  font-size: 16px;
  font-family: Futura, Avenir, Helvetica, sans-serif;
  width: 110px;
}

.query_fields {
  width: 143px;
  height: 14px;
  border-radius: 5px;
  border: 1px solid grey;
  padding: 6px 10px 5px 25px;
}

.formfield_td {
  width: 150px !important;
}

.required {
  color: red;
}

.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  font-size: 14px !important;
  line-height: normal !important;
  z-index: 0 !important;
}

.custom-autocomplete {
  width: 100%;
  /* border: 1px solid #afb8c1; */
  border-radius: 6px;
}

.custom-autocomplete.is-active {
  border-color: #4f46e5;
}

.custom-autocomplete .MuiAutocomplete-input {
  height: 10px;
}

/*end adornment*/
.custom-autocomplete .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] button {
  width: auto !important;
}

.approval_photo {
  height: 460px;
  width: 600px;
}

.purchase_photo {
  height: 460px;
  width: 460px;
}

.feedback_photo {
  height: 500px;
  width: 500px;
}

.profileContainer {
  justify-content: center;
  font-size: 17px;
  font-family: Futura, Avenir, Helvetica, sans-serif;
}

.mail {
  flex-direction: row;
  justify-content: left;
  align-items: left;
}

.leaflet-container {
  height: 500px;
  width: 600px;
}

.fullPage {
  width: 1200px;
}

/* Pagination styles */

.paginationButtons {
  width: 100%;
  height: 40px;
  list-style: none;
  display: flex;
  justify-content: center;
}

.paginationButtons a {
  padding: 10px;
  margin: 2px;
  border-radius: 5px;
  border: 1px solid #00C9FF;
  color: #00C9FF;
  cursor: pointer;
}

.paginationButtons a:hover {
  color: white;
  background-color: #00C9FF;
}

.paginationActive a {
  color: white;
  background-color: #00C9FF;
}

.paginationDisabled a {
  color: #b4efff;
  border: 1px solid #b4efff;
}

.paginationDisabled a:hover {
  color: white;
  border: 1px solid white;
  background-color: #b4efff;
}

/*styling for gmail style Loading badge*/
.vY {
  visibility: visible;
  position: absolute;
  left: 50%;
  top: 0;
  width: 85px;
  height: 30px
}


.vX {
  visibility: visible;
  font-size: 0.875rem
}

.vX .vh {
  background-color: #f9edbe;
  border: 1px solid #f0c36d;
  -moz-border-radius: 0 0 2px 2px;
  border-radius: 0 0 2px 2px;
  -moz-box-shadow: 0 2px 4px rgba(0, 0, 0, .2);
  box-shadow: 0 2px 4px rgba(0, 0, 0, .2);
  color: #222;
  padding: 6px 10px
}

.vZ {
  padding-left: 2px;
  white-space: nowrap
}

.v1 {
  font-weight: bold
}

.UC {
  text-align: center
}

.vh {
  border: 3px solid transparent;
  border-radius: 3px;
  -moz-border-radius: 3px
}

.J-J5-Ji {
  display: -moz-inline-box;
  display: inline-block
}

.UB,
.UD {
  display: block
}

#supNumber::-webkit-outer-spin-button,
#supNumber::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
  -moz-appearance: none;
}

.generate_graph :hover {
  cursor: pointer;
  background-color: "#3366cc";
  box-shadow: black;
}

.slider-container {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.drawer-button {
  width: unset;
  /* Or use 'initial' or 'inherit' depending on your needs */
  height: unset;
  /* Similarly, for height if needed */
}

.daterangepicker_search {
  background-color: #ffffff;
  border-radius: 5px;
  width: 180px;
  min-width: unset !important;
}

.daterangepicker_search .MuiInputBase-root {
  background-color: #ffffff !important;
  border-radius: 5px !important;
  width: 180px !important;
  border: 1px solid #000000;
}

.daterangepicker_search .MuiStack-root {
  background-color: #ffffff !important;
  border-radius: 5px !important;
  width: 100% !important;
}

.daterangepicker_search .MuiFormControl-root {
  background-color: #ffffff !important;
  border-radius: 5px !important;
  width: 100% !important;
}

.daterangepicker_search .MuiTextField-root {
  background-color: #ffffff !important;
  border-radius: 5px !important;
  width: 100% !important;
}

.daterangepicker_search .MuiInputBase-input {
  background-color: #ffffff !important;
  border-radius: 5px !important;
  width: 100% !important;
  height: 0.55em;
  font-size: 12.5px;
  cursor: pointer !important;
}

.qry_btns {
  border: 1px solid #000000;
  border-radius: 5px;
  height: 100%;
  margin: 1px 1px 1px 1px;
  height: 2.55em;
  margin-top: 7px;
}

.sticky-top-container {
  position: sticky;
  top: 0;
  z-index: 1000;
  /* Adjust z-index as needed */
  background-color: #fff;
  /* Optional: background color */
}

.MuiDrawer-root .MuiDrawer-docked {
  width: 0px;
}

.add_make_year .MuiFormControl-root,
.MuiTextField-root {
  width: 100% !important;
}

.add_make_year .MuiStack-root {
  height: 58px !important;
}

.add_make_year .MuiInputBase-root,
.add_make_year .MuiOutlinedInput-root {
  height: 45px;
}

.modal-footer {
  justify-content: space-between !important;
}

.image-slider-container .slick-prev:before,
.image-slider-container .slick-next:before {
  color: rgb(153, 153, 153);
}

.table-row {
  transition: height 0.3s ease;
}

/* General scrollbar styles */
::-webkit-scrollbar {
  width: 12px;
  /* Width of vertical scrollbar */
  height: 12px;
  /* Height of horizontal scrollbar */
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  /* Background of the scrollbar track */
}

::-webkit-scrollbar-thumb {
  background: #888;
  /* Color of the scrollbar thumb */
  border-radius: 6px;
  /* Rounded corners of the scrollbar thumb */
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
  /* Color of the scrollbar thumb on hover */
}

/* Firefox scrollbar styles */
html {
  scrollbar-width: thin;
  /* Makes the scrollbar thinner */
  scrollbar-color: #888 #f1f1f1;
  /* Thumb color and track color */
}

/* Target scrollable containers, including tables */
.scrollable-container::-webkit-scrollbar {
  width: 12px;
  height: 12px;
  /* Adjust for horizontal scrollbar */
}

.scrollable-container::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.scrollable-container::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 6px;
}

.scrollable-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Apply to specific tables with scroll */
.table-container::-webkit-scrollbar {
  width: 12px;
  height: 12px;
  /* For horizontal scrollbars */
}

.table-container::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.table-container::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 6px;
}

.table-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* For the entire document */

/* WebKit-based browsers (Chrome, Safari, Edge) */
::-webkit-scrollbar {
  width: 8px;
  /* Adjust the width */
}

::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  background-color: lightgrey;
}

/* Firefox */
* {
  scrollbar-width: thin;
  /* Thin scrollbar for Firefox */
  scrollbar-color: darkgrey lightgrey;
  /* Thumb and track colors */
}

.ant-space-item {
  width: 100% !important;
}

.ant-space {
  width: 100% !important;
}

.ant-space-compact {
  width: 100% !important;
}

.ant-select {
  width: 100% !important;
}

.ant-upload-list-item-thumbnail {
  margin-left: unset !important;
}

.search_select {
  width: unset !important
}

.ant-table {
  background: unset !important;
}

.ant-table-pagination {
  padding-right: 15px;
}

.ant-pagination-item-link {
  margin-left: unset !important;
}

.ant-table-row-expand-icon {
  width: 30px;
  height: 30px;
}

.ant-table-row-expand-icon-collapsed {
  width: 70px;
  height: 70px;
}

.ant-image {
  background-color: white;
  border-radius: 8px;
}
